

var key = {
  NetworkId:56,
  netWorkUrl: "https://bsc-dataseed1.binance.org/",
  Factory: "",
  Router :"",
  ETHSYMBOL: "BNB"
};

if (process.env.NODE_ENV == "production") {
  key = {
    
    contractAddress: "0x4877E0f878050E66c2963b9Fe1AD8E44f20b41DB",
    tokenAddress:"0x55d398326f99059fF775485246999027B3197955",
    tbxAddress:"0x7e8569C1780652710c78B4Df2721FF5E6a7C39f4",
    frontUrl: "https://www.tbxstaking.info/",
    baseUrl: "https://prodapi.tbxstaking.info/userapi",
    serverURL: "https://prodapi.tbxstaking.info/",
    NetworkId: 56,
    networkName: "Binance Chain",
    toFixed: 8,
    txUrl: "https://bscscan.com/tx/",
    txUrlAddress: "https://bscscan.com/address/",
    nativeCurrency: {
      name: 'Binance',
      symbol: 'ETH', // 2-6 characters long
      decimals: 18
    },
    netWorkUrl: "https://bsc-dataseed1.binance.org/",
    blockexploer: "https://bscscan.com",
    
    reactLoadr: "#19366b",   
    rpcurl: "https://bsc-dataseed1.binance.org/",
    ETHSYMBOL: "BNB",
    limit: 6,
    NetworkType: "Binance",
    facebooklink:'',
    instagramlink:'',
    twitterlink:'',
    telegramlink:''

  
    // contractAddress: "0xa2A6Cba521539EDA7ae904B9aCD619D5B27eDf82",
    // tokenAddress:"0xdAa36fab6996700FC5D3a13704CBc986e39119C8",
    // tbxAddress:"0x0a58249223f2CE3D08A8aCfc2F015EAe96A1190E",
    // frontUrl: "https://tbx-main-page.pages.dev/",
    // baseUrl: "https://tbxapi.wearedev.team/userapi",
    // serverURL: "https://tbxapi.wearedev.team/",
    // NetworkId: 97,
    // networkName: "Binance Chain",
    // toFixed: 8,
    // txUrl: "https://testnet.bscscan.com/tx/",
    // txUrlAddress: "https://testnet.bscscan.com/address/",
    // nativeCurrency: {
    //   name: 'Binance',
    //   symbol: 'ETH', // 2-6 characters long
    //   decimals: 18
    // },
    // netWorkUrl: "https://data-seed-prebsc-2-s2.binance.org:8545/",
    // blockexploer: "https://bscscan.com",
    
    // reactLoadr: "#19366b",   
    // rpcurl: "https://data-seed-prebsc-2-s2.binance.org:8545/",
    // ETHSYMBOL: "BNB",
    // limit: 6,
    // NetworkType: "Binance",
    // facebooklink:'',
    // instagramlink:'',
    // twitterlink:'',
    // telegramlink:''
  };
} 

else{
  key = {
    
  
  	contractAddress: "0xa2A6Cba521539EDA7ae904B9aCD619D5B27eDf82",//0x6496e43724568De322bAF5984C2AE227226984e2
    // tokenAddress:"0x0a58249223f2CE3D08A8aCfc2F015EAe96A1190E",
    tokenAddress:"0xdAa36fab6996700FC5D3a13704CBc986e39119C8",
    tbxAddress:"0x0a58249223f2CE3D08A8aCfc2F015EAe96A1190E",
    frontUrl: "http://localhost:3000/",
    serverURL: 'http://localhost:2053/',
    baseUrl: "http://localhost:2053/userapi",
    imgurl:"http://localhost:2053/adminapi/",
    NetworkId: 97,
    networkName: "Binance Chain",
    toFixed: 8,
    txUrl: "https://testnet.bscscan.com/tx/",
    txUrlAddress: "https://testnet.bscscan.com/address/",
    nativeCurrency: {
      name: 'Binance',
      symbol: 'ETH', // 2-6 characters long
      decimals: 18
    },
    netWorkUrl: "https://data-seed-prebsc-2-s2.binance.org:8545/",
    blockexploer: "https://bscscan.com",
    
    reactLoadr: "#19366b",   
    rpcurl: "https://data-seed-prebsc-2-s2.binance.org:8545/",
    ETHSYMBOL: "BNB",
    limit: 6,
    NetworkType: "Binance",
    facebooklink:'',
    instagramlink:'',
    twitterlink:'',
    telegramlink:''
  };
}
export default key;