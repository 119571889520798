import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.svg";
import config from "../config/config"
import { getContact } from "../API/UserActions";
export default function Footer(props) {

  const [contact, setcontact] = useState({})

  useEffect(() => {
    getContactall()
  }, [])

  async function getContactall() {
    try {
      const { result } = await getContact()
      if (result) {
        setcontact(result)
      } else {
        setcontact()
      }
    }
    catch (err) { }
  }
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="row">
            <div className="col-lg-6 col-xl-6 mb-3 mb-xl-0">
              <img src={logo} className="img-fluid mb-3" alt="img" />
              <div className="row">
                <div className="col-xl-9">
                  <p className="para mb-5" >Join us to explore the Tribex token, its groundbreaking features, and the ecosystem</p>
                </div>
              </div>
              <h5 className="mb-2">Token contract address</h5>
              <a href={config.txUrlAddress + config.tbxAddress} className="ylw" target="_blank">{config.tbxAddress}</a>
              <h5 className="mb-2">Staking contract address</h5>
              <a href={config.txUrlAddress + config.contractAddress} className="ylw" target="_blank">{config.contractAddress}</a>
            </div>
            {/* <div className="col-lg-6 col-xl-3">
              <h5>Contact us</h5>
              <p className="ylw sm" >Address</p>
              <p>{contact && contact.Address?contact.Address:''}</p>
              <p className="ylw sm" >Phone</p>
              <p>+{contact && contact.PhnNo?contact.PhnNo:''}</p>
              <p className="ylw sm" >Email</p>
              <p>{contact && contact.email?contact.email:''}</p>
            </div> */}
            <div className="col-lg-6 col-xl-3">
              <h5>Support</h5>
              <ul>
               <a href='/terms' style={{ "text-decoration": "none"}}><li>Terms of use</li></a>
                <a href='/privacy' style={{ "text-decoration": "none"}}><li>Privacy Policy</li></a>
                <a href='/about-us' style={{ "text-decoration": "none"}}> <li>About Us  </li></a>
                {/* <li>Help center</li>
                <li>Contact</li> */}
              </ul>
            </div>
            <div className="col-lg-6 col-xl-3">
              <h5>Quick Links</h5>
              <ul>
                {/* <li> Home </li> */}
          
                <a href='/' style={{"text-decoration": "none"}}><li> Staking  </li></a>
                <a href='/' style={{"text-decoration": "none"}}><li>Whitepaper</li></a>
              </ul>
            </div>


          </div>
        </div>
        <div className="footer_flx">
          <div className="footer_panel_left">
            <p>
              Copy right © {new Date().getFullYear()}  <span  style={{ "color": "white","cursor":"default"}}> TBX</span> <span  style={{ "text-decoration": "none", "cursor":"default"}} className="ylw"> STAKING</span>,
              All rights Reserved
            </p>
          </div>
          <div className="footer_panel_right">
            <ul className="footer_social_links">
              {/* <li>
                <a href={contact && contact.FaceBook ?contact.FaceBook:''} target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href={contact && contact.Instagram ? contact.Instagram:''} target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href={contact && contact.Twitter?contact.Twitter:''} target="_blank">
                  <i className="fa-brands fa-x-twitter"></i>
                </a>
              </li> */}
              <li>
                <a href={contact && contact.Telegram ?contact.Telegram:''} target="_blank">
                  <i className="fab fa-telegram-plane"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>



    </footer>
  );
}
